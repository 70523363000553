<template>
  <layout width="540">
    <template slot="header">
      {{ title }}
    </template>

    <template>
      <validation-observer ref="form">
        <v-form>
          <form-subheader title="General info" />
          <validation-provider
            v-slot="{ errors }"
            name="Plan name"
            rules="required"
          >
            <v-text-field
              v-model.trim="state.name"
              label="Plan name"
              :error-messages="errors"
            />
          </validation-provider>
          <form-switcher
            v-model="state.displayByDefault"
            title="Display by default"
          />
          <form-subheader title="Image" />
          <form-text>
            Important! For the correct display of SVG files, it is necessary
            that they contain the Width and Height values for the viewBox.
          </form-text>
          <image-uploader
            :src="planUrl"
            @change="handleChangeImage"
            @clear="handleClearImage"
            @load="handleLoadImage"
          />
          <form-subheader title="Position" />
          <form-text v-if="!edit">
            The plan will be created in the center of the current map position.
            Its geometry may be changed from the edit item menu.
          </form-text>
          <v-autocomplete
              v-model="state.geotagId"
              :items="geotagList"
              item-text="name"
              item-value="id"
              label="Geotag"
          />
          <v-text-field v-model="state.centerLat" label="Center latitude" />
          <v-text-field v-model="state.centerLon" label="Center longitude" />
          <v-text-field v-model="state.rotation" label="Rotation(deg)" />
          <form-subheader title="Size" />
          <v-text-field
            :value="state.width"
            label="Width(m)"
            @input="updateWidth"
          />
          <v-text-field
            :value="state.height"
            label="Height(m)"
            @input="updateHeight"
          />
          <form-subheader title="Description" />
          <v-textarea
            v-model="state.description"
            no-resize
            rows="1"
            auto-grow
          />
        </v-form>
      </validation-observer>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="submit"
      >
        {{ submitBtnTitle }}
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { computed, onMounted, ref } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { planService } from '@/modules/plans/api';
import { imageService, objectPropertyService } from '@/modules/common/api';
import { mediaClient } from '@/provider';

import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { useGeotags } from '@/modules/geotags/compositions/geotags';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'PlanEdit',
  components: {
    Layout,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    planId: {
      type: [Number, String],
      default: ''
    },
    defaultLonLat: {
      type: Array,
      default: () => [0, 0]
    },
    createCopy: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit, root }) {
    const state = ref({
      schemaId: '',
      name: '',
      description: '',
      width: 0,
      height: 0,
      rotation: 0,
      centerLat: 0,
      centerLon: 0,
      imageId: '',
      displayByDefault: false,
      geotagId: ''
    });

    const plan = ref({});
    const form = ref(null);

    const edit = computed(() => props.planId);
    const title = computed(() =>
      edit.value ? (props.createCopy ? 'Copy plan' : 'Edit plan') : 'Add plan'
    );
    const submitBtnTitle = computed(() =>
      edit.value && !props.createCopy ? 'Save' : 'Add'
    );

    const aspectRatio = ref(1);
    const imageLoaded = ref(false);

    const planUrl = computed(() =>
      mediaClient.getImageUrl(state.value.imageId)
    );

    const { list: geotagList, currentGeotagExceptAllItem } = useGeotags();

    const handleChangeImage = async e => {
      console.log(e);
      state.value.imageId = await imageService.upload(e.target.files[0]);
    };

    const handleClearImage = () => {
      state.value.imageId = '';
      imageLoaded.value = false;
    };

    const handleLoadImage = async image => {
      imageLoaded.value = true;
      const size = getSizeInPixels(image);
      aspectRatio.value = size.width / size.height;
      if (!state.value.width) {
        updateWidth(30);
      }
    };

    const getSizeInPixels = image => {
      return {
        width: image.naturalWidth,
        height: image.naturalHeight
      };
    };

    const updateWidth = value => {
      state.value.width = value;
      if (imageLoaded.value) {
        state.value.height = value / aspectRatio.value;
      }
    };
    const updateHeight = value => {
      state.value.height = value;
      if (imageLoaded.value) {
        state.value.width = value * aspectRatio.value;
      }
    };

    const handleSubmit = async () => {
      form.value.validate().then(async success => {
        if (!success) {
          return;
        }
        if (!edit.value || props.createCopy) {
          // create new plan
          plan.value = await planService.create({
            name: state.value.name,
            description: state.value.description,
            schemaId: state.value.schemaId
          });
        } else {
          await planService.update(props.planId, {
            name: state.value.name,
            description: state.value.description
          });
        }
        await objectPropertyService.updateValues([
          {
            id: plan.value.positionCenter.id,
            value: {
              alt: Number(plan.value.positionCenter.value.alt),
              lon: Number(state.value.centerLon),
              lat: Number(state.value.centerLat)
            }
          },
          {
            id: plan.value.positionWidth.id,
            value: Number(state.value.width)
          },
          {
            id: plan.value.positionHeight.id,
            value: Number(state.value.height)
          },
          {
            id: plan.value.positionRotation.id,
            value: Number(state.value.rotation)
          },
          {
            id: plan.value.imageId.id,
            value: state.value.imageId
          },
          {
            id: plan.value.infoDisplayByDefault.id,
            value: state.value.displayByDefault
          },
          {
            id: plan.value.positionGeotagId.id,
            value: state.value.geotagId
          }
        ]);

        if (!edit.value) {
          root.$router.push({
            name: 'plan_card',
            params: {
              planId: plan.value.id
            }
          });
        }
        emit('close');
      });
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    onMounted(async () => {
      if (!edit.value || props.createCopy) {
        state.value.schemaId = await planService.fetchPlanSchemaId();
      }
      if (!edit.value) {
        state.value.centerLon = props.defaultLonLat[0] || 0;
        state.value.centerLat = props.defaultLonLat[1] || 0;
        state.value.geotagId = currentGeotagExceptAllItem.value.id;
      } else {
        plan.value = await planService.fetch(props.planId);

        const {
          positionCenter,
          positionWidth,
          positionHeight,
          positionRotation,
          positionGeotagId,
          infoDisplayByDefault,
          imageId,
          name,
          description
        } = plan.value;

        state.value = {
          ...state.value,
          centerLat: positionCenter.value.lat,
          centerLon: positionCenter.value.lon,
          width: positionWidth.value,
          height: positionHeight.value,
          rotation: positionRotation.value,
          imageId: imageId.value,
          geotagId: positionGeotagId.value,
          name: props.createCopy ? `${name} Copy` : name,
          description,
          displayByDefault: infoDisplayByDefault.value
        };
      }
    });

    return {
      state,
      loading,
      title,
      submitBtnTitle,
      submit,
      planUrl,
      handleClearImage,
      handleChangeImage,
      handleLoadImage,
      updateWidth,
      updateHeight,
      edit,
      form,
      geotagList
    };
  }
};
</script>
